import React from 'react'
import { Auth } from "aws-amplify";
import {connect} from "react-redux";

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            newPassword: '',
            newPasswordConfirm: '',
            isLoading: false,
            message: ''
        };
    }

    onSubmit = (event) => {
        event.preventDefault();

        if (this.state.newPassword !== this.state.newPasswordConfirm) {
            this.setState({message: 'Die Passwörter stimmen nicht überein.'})
        } else {
            this.setState({isLoading: true});
            Auth.completeNewPassword(
                this.props.user,
                this.state.newPassword,
                null //required user attributes
            ).then(user => {
                this.props.onSignIn(user);
                this.props.history.push('/llm');
            }).catch(err => {
                console.log(err);
                if (err.code === 'NotAuthorizedException') {
                    this.setState({
                        message: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an',
                        isLoading: false
                    });
                } else {
                    this.setState({
                        message: "Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut",
                        isLoading: false
                    });
                };
            });
        }
    }


    render() {
        return (
            <main className="o-page-wrap  o-page-wrap--small">
                <div className="o-page-wrap u-mb-large">
                    <header className="o-even-slimmer-page-wrap  u-text-center">
                        <h1>Passwort ändern</h1>
                        <p>Bitte ändern Sie das Passwort. Das neue Passwort muss mindestens 8 Zeichen umfassen und ein numerisches,
                            ein großgeschriebenes und ein kleingeschriebenes Zeichen sowie ein Sonderzeichen enthalten.</p>
                        <p className="c-error-message" id="passwortChange" align='center'>{this.state.message}</p>

                        <div id="login-form" className="o-layout  o-layout--center">
                            <div className="o-layout__item  u-1/3">        
                                <form onSubmit={this.onSubmit}>
                                    <fieldset className="o-fieldset u-mb">
                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/1">
                                                    <label htmlFor="username">Benutzername</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="text" id="username" name="username"
                                                           value={this.state.username}
                                                           onChange={(e) => {
                                                               this.setState({username: e.target.value})
                                                           }} required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/1">
                                                    <label htmlFor="password">Das alte Passwort</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="password" id="password" name="password"
                                                            value={this.state.password}
                                                            onChange={(e) => {
                                                                this.setState({password: e.target.value})
                                                            }} required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/1">
                                                    <label htmlFor="newpw">Das neue Passwort</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="password" id="newpw" name="newpw"
                                                            value={this.state.newPassword}
                                                            onChange={(e) => {
                                                                this.setState({newPassword: e.target.value})
                                                            }} required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/1">
                                                    <label htmlFor="confirmPassword">Das neue Passwort bestätigen</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="password" id="confirmPassword"
                                                            name="confirmPassword" value={this.state.newPasswordConfirm}
                                                            onChange={(e) => {
                                                                this.setState({newPasswordConfirm: e.target.value})
                                                            }} required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  


                                        <div className="o-fieldset__row">
                                            <div className="o-layout o-layout--center">
                                                <div className="o-layout__item  u-1/1 u-text-center">
                                                    <button className="c-btn" type="submit" disabled={this.state.isLoading}>
                                                    <span className="c-btn__text">Abschicken</span>
                                                    {this.state.isLoading ?
                                                        <div className="c-spinner c-spinner--small">
                                                            <svg>
                                                                <circle className="c-spinner__indicator" cx="9" cy="9"
                                                                        r="8"
                                                                        fill="none"/>
                                                            </svg>

                                                        </div>
                                                    : null}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div> 
                        </div>                   
                    </header>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

export default connect(mapStateToProps)(ChangePassword);