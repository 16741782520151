import React from 'react'
import { Auth } from "aws-amplify";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isLoading: false,
            message: ''
        };
    }

    onSubmit = event => {
        event.preventDefault();

        this.setState({isLoading: true});
        Auth.signIn(this.state.username, this.state.password)
            .then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    this.props.onSignIn(user);
                    // no need to set the loading state as this component is dismounted
                    this.props.history.push('/change_password');
                } else {
                    this.props.onSignIn(user);
                    this.props.history.push('/llm');
                }
                }).catch(err => {
                    console.log(err);
                    this.setState({
                        message: "Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut",
                        isLoading: false
                    });
        });
    }
    render() {
        return (
            <main className="o-page-wrap  o-page-wrap--small">
                <div className="o-page-wrap u-mb-large">
                    <header className="o-even-slimmer-page-wrap  u-text-center">
                        <h1>Login</h1>
                        <p>Sollten Sie Probleme bei der Anmeldung haben, wenden Sie sich bitte an das DNA Team</p>
                        <p className="c-error-message" id="main_login" align='center'>{this.state.message}</p>
                        <div id="login-form" className="o-layout  o-layout--center">
                            <div className="o-layout__item  u-1/3">
                            <form onSubmit={this.onSubmit}>
                                <fieldset className="o-fieldset u-mb">
                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/1">
                                                <label htmlFor="username">Benutzername</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="username"
                                                           name="username" value={this.state.username}
                                                           onChange={(e) => {
                                                               this.setState({username: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/1">
                                                <label htmlFor="password">Passwort</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="password" id="password"
                                                           name="password" value={this.state.password}
                                                           onChange={(e) => {
                                                               this.setState({password: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="o-fieldset__row">
                                        <div className="o-layout o-layout--center">
                                            <div className="o-layout__item  u-1/1 u-text-center">
                                                <button className="c-btn" type="submit" disabled={this.state.isLoading}>Abschicken</button>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        </div>
                    </header>
                </div>
            </main>
        )
    }
}
export default Login
