const initialState = {
    userGroups: [],
    user: null,
    isAdmin: false,
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case 'REPLACE_GROUPS':
            return {
                ...state,
                userGroups: action.value
            };
        case 'SET_USER':
            return {
                ...state,
                user: action.value
            };
        case 'SET_IS_ADMIN':
            return {
                ...state,
                isAdmin: action.value
            };
        default:
            return state;
    }
};

export default reducer;
