export function strToInt(strValue) {
    const trimmedValue = strValue.trim();
    if (trimmedValue === "" || /\D/.test(trimmedValue)) { return null; }
    const num = parseInt(trimmedValue);
    if (isNaN(num)) { return null; }
    return num;
}

export function strToFloat(strValue) {
    if (strValue.trim() == "") { return null; }
    const num = Number(strValue);
    if (isNaN(num)) { return null; }
    return num;
}

export function strToConstrInt(strValue, min, max, exclConstr=false) {
    const num = strToInt(strValue);
    if (num === null) { return null; }
    if (exclConstr) {
        if ((min != null && num <= min) || (max != null && num >= max)) { return null; }
    }
    else {
        if ((min != null && num < min) || (max != null && num > max)) { return null; }
    }
    return num;
}

export function strToConstrFloat(strValue, min, max, exclConstr=false) {
    const num = strToFloat(strValue);
    if (num === null) { return null; }
    if (exclConstr) {
        if ((min != null && num <= min) || (max != null && num >= max)) { return null; }
    }
    else {
        if ((min != null && num < min) || (max != null && num > max)) { return null; }
    }
    return num;
}

export const strToPositiveInt = (strValue) => strToConstrInt(strValue, 0, null, true);
export const strToPositiveFloat = (strValue) => strToConstrFloat(strValue, 0, null, true);
