import React from 'react'
import axios from "axios";
import { Auth } from 'aws-amplify';
import {connect} from "react-redux";
import { strToInt, strToFloat, strToPositiveInt, strToPositiveFloat, strToConstrFloat } from './utils'

const llmParamSpace = {
    max_new_tokens: strToPositiveInt,
    temperature: strToPositiveFloat,
    top_p: (strValue) => strToConstrFloat(strValue, 0, 1, true),
    top_k: strToPositiveInt,
}

class FFP extends React.Component {

    constructor(props) {
        super(props);
        //Scroll to target
        this.ScrollToPredictors = React.createRef()
        this.state = {
            isLoading: false,
            isShown: false,
            userPrompt: "",
            advSettings: false,
            llmParams: {},
            llmParamErrors: new Set(),
            received_predictors: [{generated_text: ""}],
            //Errors for input validation
            promptError: "",
            promptErrorFlag: "",
            //Errors for interaction with the backend
            interactionError: "",
            interactionErrorStatus: "",
            interactionErrorType: "",
            interactionErrorFlag: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateLlmParam = this.updateLlmParam.bind(this);
        this.handlePromptSubmit = this.handlePromptSubmit.bind(this);
        this.closeModal= this.closeModal.bind(this);
    }

    componentDidMount() {
        // Axios response interception (response is checked before then/catch)
        this.requestInterceptor = axios.interceptors.response.use(
            (response) => {
                if (response.data.errorMessage) {
                    const error = new Error(response.data.message);
                    error.response = response;
                    throw error;
                } else if (Array.isArray(response.data) && response.data[0].generated_text === "") {
                    // empty answer
                    const error = new Error("empty answer");
                    throw error;
                } else {
                    this.setState({interactionErrorFlag: false });
                    return response;
                }
            });

        // Axios request interception: checking the request before it is sent
        this.requestInterceptor = axios.interceptors.request.use(
            (config) => {
                    return config;
            },
            function (error) {
                // Request error
                this.setState({
                    interactionErrorFlag: true,
                    interactionErrorStatus: "error",
                    interactionErrorType: "Technischer Fehler",
                    interactionError: "Bitte versuchen Sie es nochmal oder wenden Sie sich an das DNA Team"
                 });
                console.log('Request error');
                return Promise.reject(error);
            });
    };

    /* What happens onChange - for all forms */
    handleChange(event, valueProp='value') {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: target[valueProp]
        });
    }

    /* What happens onChange - for llm parameters */
    updateLlmParam(event) {
        this.handleChange(event)
        const strValue = event.target.value;
        const llmParam = event.target.name.slice(9);  // slice to remove "llmParam-" prefix
        if (strValue.trim() == "") {
            delete this.state.llmParams[llmParam];
            this.state.llmParamErrors.delete(llmParam);
        } else {
            const value = llmParamSpace[llmParam](strValue);
            if (value === null) {
                delete this.state.llmParams[llmParam];
                this.state.llmParamErrors.add(llmParam);
            } else {
                this.state.llmParams[llmParam] = value;
                this.state.llmParamErrors.delete(llmParam);
            }
        }
    }

    /* Client-side validation for the first form */
    validatePrompt = () => {

        let isError = false;

        function countWords(str) {
            return str.trim().split(/\s+/).length;
        }

        const time_errors = {
            promptError: "",
            promptErrorFlag: "is-success"
        };
        if (countWords(this.state.userPrompt) > 750) {
            isError = true;
            time_errors.promptErrorFlag = "is-error";
            time_errors.promptError = "Der Text überschreitet die maximale Länge von 750 Wörtern";
        }

        this.setState({
            ...this.state,
            ...time_errors
        });

        return isError;
    }


    /* Submit for the first form */
    handlePromptSubmit(event){

        event.preventDefault();
        const input_err = this.validatePrompt(); /* Run client-side validation for userPrompt */

        //If the input is correct, send prompt to llm backend
        if (!input_err) {
            this.setState(
                {isLoading: true},
                () => {
                    console.log(
                        "Loading?: ",
                        this.state.isLoading
                    );
                }
            );
            Auth.currentSession()
                .then(session => {
                    const axiosConfig = {
                        headers: {
                            'Authorization': session.getIdToken().getJwtToken(),
                            'x-api-key': process.env.REACT_APP_AUDIT_KEY,
                            'content-type': 'application/json'
                        }
                    };
                    axios.post(process.env.REACT_APP_INPUT_ENDPOINT, {prompt: this.state.userPrompt, parameters: this.state.llmParams}, axiosConfig)
                        .then(response => {
                            /* Show the block with predictions (if not shown yet) + scroll to them */
                            this.setState({
                                received_predictors: JSON.parse(JSON.stringify(response.data)),
                                isShown: true,
                                isLoading: false
                            });
                            this.ScrollToPredictors.current.scrollIntoView()
                        })
                        .catch((error) => {
                            // Response received, but wrong
                            this.setState({
                                interactionErrorFlag: true,
                                interactionErrorStatus: "error",
                                interactionErrorType: "Technischer Fehler",
                                interactionError: "Bitte versuchen Sie es nochmal oder wenden Sie sich an das DNA Team",
                                isLoading: false
                            });
                            if (error.response) {
                                console.log("Response error! Data " + error.response.data +
                                            "status " + error.response.status +
                                            "headers " + error.response.headers );
                                if (error.response.status === 504) {
                                    console.log("Timeout")
                                    this.setState({
                                        interactionErrorType: "Timeout",
                                        interactionError: "Das Modell brauchte zu lange, um eine Antwort zu generieren. Bitte versuchen Sie es noch einmal oder wenden Sie sich an das DNA Team."
                                    })
                                }
                                else if (error.response.status === 503) {
                                    console.log(error.response)

                                    if (error.response.data.errorType == 'PromptTooLongError') {
                                        console.log("Text zu lang")
                                        this.setState({
                                            interactionErrorType: "Text ist für die aktuellen Einstellungen zu lang",
                                            interactionError: "Versuchen Sie, den Text zu kürzen, oder wenden Sie sich an das DNA Team"
                                        });
                                    }
                                    else{
                                        console.log("Modell nicht verfügbar")
                                        this.setState({
                                            interactionErrorType: "Modell aktuell nicht verfügbar",
                                            interactionError: "Aus Kostengründen ist das Modell ausgeschaltet. Bitte wenden Sie sich an das DNA Team"
                                        });
                                    }
                                }
                            } else if (error.message == "empty answer") {
                                console.log("The model generated an empty answer!");
                                this.setState({
                                    interactionErrorType: "Sprachlos",
                                    interactionError: "Ups, das Modell hat keine Antwort generiert. Versuchen Sie es erneut oder ändern Sie Ihre Anfrage."
                                });
                            } else if (error.request) {
                                console.log("No response was received!");
                                // we might come into this if branch when the aws api gateway times out (29 secs)
                                // because then the api gateway sends its own response without using custom headers
                                // which leads to the situation that cors headers are not set properly and
                                // thus axios handles this with a network error (even though the api gateway
                                // response is otherwise valid and has a status code of 504).
                                // --> to tackle this problem, the aws lambda function timeout is set to 27 secs.
                            } else {
                                // Wrong request
                                console.log('Error by setting up the request!', error.message);
                            }
                        });
                })
                .catch(err => {
                   console.log("Login error " + err);
                    this.setState({
                       isLoading: false,
                       interactionErrorFlag: true,
                       interactionErrorStatus: "error",
                       interactionError: "Bitte melden Sie sich erneut an",
                       interactionErrorType: "Technischer Fehler"
                    });
                });
        };
    }


    /* Closing error popups */
    closeModal(event) {
        console.log("Event id " + event.target.id);
        if(event.target.id === "modal_okay_button" || event.target.id === "modal_close_button") {
            this.setState({
                interactionErrorFlag: false,
                interactionErrorStatus: "",
                interactionError: "",
                interactionErrorType: ""});
        }
    }

    render() {
        return (

            <main className="o-page-wrap  o-page-wrap--small">
                <div className="o-page-wrap u-mb-large">
                    {/* Rendering the first form with KW/Year input */}
                    <div id='userPrompt' className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none u-text-center">
                        <form onSubmit={this.handlePromptSubmit}>
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <p class="u-text-alert">Testbetrieb!</p>
                            <h1>Your Large Language Model</h1>
                            <h3>Powered by the DNA</h3>
                            <p className="c-error-message" id="userPrompt" align='center'>{this.state.promptError}</p>
                        </header>
                        <div className="o-layout  o-layout--center">
                            <div className="o-layout__item  u-1/2">
                                <div className="o-fieldset__row">
                                        <div className="c-form-field  ">
                                            <div className="c-form-field__box">
                                                <div className="c-input  c-input--textarea">
                                                    <textarea
                                                        className={"c-input__input " + this.state.promptErrorFlag}
                                                        id="userPrompt"
                                                        name="userPrompt"
                                                        // type="text"
                                                        required
                                                        placeholder="Beschreiben Sie hier Ihre Frage oder Aufgabe..."
                                                        value={this.state.userPrompt}
                                                        onChange={this.handleChange}
                                                        cols="25"
                                                        rows="5"
                                                    />
                                                    {/* <i className="c-input__state-icon"  aria-hidden="true" role="img"></i> */}
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className="o-fieldset__row u-text-center">
                                    <button className="c-btn" type="submit" disabled={this.state.isLoading} aria-disabled={this.state.isLoading}>
                                        <span className="c-btn__text" >Abschicken</span> {
                                            this.state.isLoading ?
                                                <div className="c-spinner c-spinner--small" aria-live="polite" aria-busy="true">
                                                     <div className="c-spinner__loader">
                                                        <span class="c-spinner__element"></span>
                                                     </div>
                                                </div>: null
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Advanced Settings */}
                        <br/>
                        <div class="c-toggle  ">
                            <label for="advSettings">
                                <input
                                    class="c-toggle__input"
                                    type="checkbox"
                                    name="advSettings"
                                    id="advSettings"
                                    checked={this.state.advSettings}
                                    onChange={(event) => this.handleChange(event, 'checked')}
                                />
                                <span class="c-toggle__text">
                                    Erweiterte Einstellungen
                                </span>
                            </label>
                        </div>

                        {this.state.advSettings &&
                        Object.keys(llmParamSpace).map((llmParam) => (
                            <div class="c-form-field  ">
                                <label for={"llmParam-" + llmParam} class="c-form-field__label">{llmParam[0].toUpperCase() + llmParam.slice(1)}</label>
                                <div class="c-form-field__box">
                                    <div class="c-input  ">
                                        <input
                                            class={"c-input__input  " + (this.state.llmParamErrors.has(llmParam) ? "is-error" : "")}
                                            id={"llmParam-" + llmParam}
                                            name={"llmParam-" + llmParam}
                                            type="text"
                                            value={this.state["llmParam-" + llmParam] ?? ""}
                                            // placeholder="Zahl größer 0, z.B. 0.8"
                                            data-tr-component="Input Field"
                                            data-tr-function="Text Input"
                                            onChange={this.updateLlmParam}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}

                        </form>
                    </div>

                    <br/>
                    {/* Conditional rendering of the errors and warnings: the popup is shown if an error happened */}
                    {this.state.interactionErrorFlag === true &&

                    <div className={"c-modal c-modal--small c-modal--"+this.state.interactionErrorStatus} aria-hidden="false" id="modal" >
                        <div className="c-modal__overlay  js-modal-close" tabIndex="-1"></div>
                        <div className="c-modal__wrapper" role="dialog" aria-labelledby="foo-bar-baz">
                            <header className="c-modal__header u-mb-none u-mb@s">
                                <div className="c-modal__title__wrapper">
                                    <i className="c-modal__status-icon"></i>
                                    {
                                    this.state.interactionErrorType ?
                                    <h1 className="c-modal__title" id="foo-bar-baz">{this.state.interactionErrorType}</h1>:
                                    <h1 className="c-modal__title" id="foo-bar-baz">Technischer Fehler</h1>
                                    }

                                </div>
                                <button className="c-modal__close-btn  js-modal-close" id="modal_close_button"
                                    type="button" aria-label="Close this dialog window."
                                    onClick={this.closeModal}  >
                                </button>
                            </header>
                            <div className="c-modal__content u-mb-none u-mb@s" role="document">
                            {
                                this.state.interactionError ?
                                    <div><p>{this.state.interactionError}</p></div>:
                                    <div><p>Bitte versuchen Sie es nochmal oder wenden Sie sich an das DNA Team</p></div>
                            }
                            </div>
                            <div className="c-modal__footer">
                                <div className="c-modal__actions">
                                    <div className="c-modal__actions__item">
                                        <button className="c-btn  js-modal-close  js-modal-autofocus"
                                            id="modal_okay_button"
                                            aria-label="Close this dialog window."
                                            onClick={this.closeModal}>
                                            <span className="c-btn__text">Ok</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {/* Conditional rendering of the predictors: the form is not shown until predictors are received */}
                    {this.state.isShown === true &&

                    <div id="input_parameter" className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none u-text-center">
                         <br/>
                        <form onSubmit={this.handlePredictorsSubmit}>
                            <fieldset className="o-fieldset u-mb" style={{textAlign: "center"}}>
                                <div className="o-page-wrap u-mb-large" ref={this.ScrollToPredictors}>
                                    <span style={{ textAlign: "center", align: "center" }}>
                                        {/* <div className="o-layout  o-layout--center"> */}
                                            <div class="c-inbox-filter">
                                                <h3 class="c-inbox-filter__title">
                                                    Antwort
                                                </h3>
                                                <div class="c-inbox-filter__body">
                                                    <p class="pre-wrap-left">{this.state.received_predictors[0].generated_text.trim()}</p>
                                                </div>
                                            </div>
                                        {/* </div> */}
                                    </span>
                                </div>
                            </fieldset >
                        </form>
                    </div>
                    }
                </div>
            </main >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        userGroups: state.userGroups,
        isAdmin: state.isAdmin,
    };
};

export default connect(mapStateToProps)(FFP);