import React from "react";
import {Link} from 'react-router-dom';

class Impressum extends React.Component {
    
    render() {
        return (
            <main className="o-page-wrap  o-page-wrap--small">
                <div className="o-page-wrap">
                    <nav>
                        <ul className="c-breadcrumb">
                            <li>
                                <Link to="/"><i aria-hidden="true" className="c-icon c-icon--[house]"></i></Link>
                            </li>
                            <li><span>Impressum</span></li>
                        </ul>
                    </nav>
                </div>
                <div className="c-hero-teaser c-hero-teaser--no-image c-hero-teaser--auto">
                    <div id="js-hero-teaser-content" className="c-hero-teaser__content">
                        <div className="o-page-wrap">
                            <header className="c-hero-teaser__heading">
                                <div className="c-hero-teaser__title">
                                    <h1>
                                        <span className="c-hero-teaser__title-row">Impressum</span><br/>
                                    </h1>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
                <section className="js-content-section o-content-section " id="volkswagen_fs">
                    <div className="o-component-wrapper">
                        <div className="o-page-wrap o-page-wrap--small">
                            <p><b>Volkswagen Financial Services AG</b>
                                <br/> Gifhorner Straße 57
                                <br/> 38112 Braunschweig
                                <br/> +49 (0)531 / 212-3888
                                <br/> E-Mail: <a href="mailto:info@vwfs.com"
                                        target="_self">info@vwfs.com</a>
                                <br/><a href="https://www.vwfs.com/">www.vwfs.com</a>
                            </p>
                            <p>
                                <br/>Sitz der Gesellschaft: Braunschweig
                                <br/>Amtsgericht Braunschweig: HRB 3790
                                <br/>USt-Identifikations-Nr.: DE 811115544
                            </p>
                            <p>
                                <br/><b>Vorsitzender des Aufsichtsrats: </b>
                                <br/>Dr. Arno Antlitz

                            </p>
                            <p>
                                <br/><b>Vorstandsmitglieder:</b>
                                <br/>Dr. Christian Dahlheim (Vorsitzender des Vorstands)
                                <br/>Anthony Bandmann
                                <br/>Dr. Alexandra Baum-Ceisig
                                <br/>Dr. Alena Kretzberg 
                                <br/>Frank Fiedler
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default Impressum