import React from 'react'
import {
    Link
} from 'react-router-dom';
import {connect} from "react-redux";

class Header extends React.Component {
    onLogout = () => {
        this.props.onLogout();
    };

    render() {
        return (
            <header class="c-header__wrapper">
                <div class="c-header  c-header--static">
                    <div class="c-header__content-wrapper">
                        <div class="c-header__logo">
                            <a class="c-logo  c-logo--has-image" href="#0">
                                <picture>
                                    <source srcset="https://cdn.bronson.vwfs.tools/bluelabel/v/15.3.0/img/logo-small-screen.svg" media="(max-width: 720px)"/>
                                    <img src="https://cdn.bronson.vwfs.tools/bluelabel/v/15.3.0/img/logo.svg" alt="" class="c-logo__image"/>
                                </picture>
                            </a>
                        </div>
                        <div class="c-header__nav">
                            <div class="c-site-nav  js-site-nav">
                                <div class="c-site-nav__panel  js-site-nav__navs  is-collapsed">
                                    <nav class="c-site-nav__navs" aria-label="Main Navigation">
                                        <ul class="c-site-nav__nav">
                                            <li class="c-site-nav__item">
                                                { this.props.user ?
                                                    <button class="c-btn  " onClick={() => this.onLogout()}><span class="c-btn__text">Logout</span></button>
                                                    : null }
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        userGroups: state.userGroups,
        isAdmin: state.isAdmin,
    };
};

export default connect(mapStateToProps)(Header);