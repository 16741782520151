import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import {connect} from "react-redux";

import './App.css';
import { Auth } from "aws-amplify";

import Header from './container/Header/Header'
import Footer from './container/Footer/Footer'
import FFP from './container/FFP/FFP'
import ErrorBoundary from './container/FFP/ErrorBoundary'
import License from "./container/License/License";
import Impressum from "./container/Impressum/Impressum";
import ScrollToTop from "./container/ScrollToTop";
import Login from "./container/Login/Login";
import ChangePassword from "./container/ChangePassword/ChangePassword";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isAuthenticating: true,
        isLoading: false,
    };


    // need to bind them for some reason even though it is an arrow function
    this.onSignIn = this.onSignIn.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }


  async componentDidMount() {
      // check the user session if there already is an authenticated user
      try {
          const user = await Auth.currentAuthenticatedUser();
          this.props.onSetUser(user);

          this.props.onSetIsAdmin(false);

          if (user.signInUserSession.accessToken.payload['cognito:groups']) {
              this.checkGroups(user.signInUserSession.accessToken.payload['cognito:groups']);
          }
      } catch(err) {
          if (err !== 'No current user') {
            this.props.onSetUser(null);
          }
      }

      this.setState({
          isAuthenticating: false,
          isLoading: false
      });
  }

    checkGroups (groups) {
        this.props.onSetUserGroups(groups);
        if(groups.indexOf('FFP_Admin') > -1) {
            this.props.onSetIsAdmin(true);
        }
    }

    onLogout = () => {
        Auth.signOut()
            .then(() => {
                this.props.onSetUser(null);
                this.props.onSetIsAdmin(false);
                this.props.history.push('/');
            }).catch(err => {
            console.log(err);
        });
    };


    onSignIn = (user) => {
        this.setState({
            isLoading:false,
            isAuthenticating: false
        });
        this.props.onSetUser(user);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // a redirect is triggered from Login component
        } else {
             if (user.signInUserSession.accessToken.payload['cognito:groups']) {
                 this.checkGroups(user.signInUserSession.accessToken.payload['cognito:groups']);
             }
        }
    };

  render() {
    const childProps = {
        onSignIn: this.onSignIn,
        onLogout: this.onLogout,
      version: this.props.version,
    };

    // unauthenticated routes
    let routes = (
          <Switch>
            <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
              <Route  path="/login" render={ (props) => <Login {...props} {...childProps} />} />
              <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />}/>
              <Route  path="/impressum" render={ (props) => <Impressum {...props} {...childProps} />}/>
            <Route  path="/license" render={ (props) => <License {...props} {...childProps} />}/>
            <Redirect to="/" />
          </Switch>
    );

      // authenticated routes
      if (this.props.user && !this.props.isAdmin) {
          routes = (
              <Switch>
                  <Route  exact path="/" render={ (props) => <FFP {...props} {...childProps}/>} />
                  <Route  path="/llm" render={ (props) => <FFP {...props} {...childProps} />} />
                  <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />}/>
                  <Route  path="/impressum" render={ (props) => <Impressum {...props} {...childProps} />}/>
                  <Route  path="/license" render={ (props) => <License {...props} {...childProps} />}/>
                  <Redirect to="/" />
              </Switch>
          );
      }

      // make sure the app does not render until the authentication state is clear!
    return ( !this.state.isAuthenticating &&
        <div className="o-root">
          <Router>
            <div>
              <Header {...this.props} {...childProps} />
              <ErrorBoundary>
                <ScrollToTop />
                {routes}
              </ErrorBoundary>
              <Footer {...this.props} {...childProps} />
            </div>
          </Router>
        </div>
    );
  }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        userGroups: state.userGroups,
        isAdmin: state.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUser: (value) => dispatch({type: 'SET_USER', value}),
        onSetUserGroups: (value) => dispatch({type: 'REPLACE_GROUPS', value}),
        onSetIsAdmin: (value) => dispatch({type: 'SET_IS_ADMIN', value}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
