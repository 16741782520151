import React from 'react'

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) { 
        return { 
            hasError: true 
        };  
    }

    render() {
      if (this.state.hasError) {
        return (
        <main className="o-page-wrap  o-page-wrap--small">
          <div className="o-page-wrap u-mb-large">
            <div id='error_boundary' className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none u-text-center">
              <header className="o-even-slimmer-page-wrap  u-text-center">
                <h1 className="u-h2">...hier ist etwas schiefgelaufen</h1>
                <p>Versuchen Sie es nochmal oder wenden Sie sich an das DNA Team.</p>
              </header>  
              </div>
          </div> 
      </main> 
      );
    }
    return this.props.children; 
  }
}





export default ErrorBoundary