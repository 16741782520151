import React from "react";
import {Link} from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer class="c-footer">
                <div class="c-footer__module  c-footer__module--meta-nav">
                    <div class="o-page-wrap">
                        <div class="c-footer-breadcrumb">
                            <nav class="c-footer-meta-bar">
                                <ul class="c-footer-meta-nav">
                                    <li class="c-footer-meta-nav__item">
                                        <span class="c-footer-copyright">Volkswagen Financial Services AG</span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="c-footer__module  c-footer__module--meta-nav">
                    <div class="o-page-wrap">
                        <nav class="c-footer-meta-bar">
                            <ul class="c-footer-meta-nav">
                                <li class="c-footer-meta-nav__item">
                                    <a class="c-footer-meta-nav__link" href="/impressum">
                                        Impressum
                                    </a>
                                </li>
                                <li class="c-footer-meta-nav__item">
                                    <a class="c-footer-meta-nav__link" href="/license">
                                        License Notes
                                    </a>
                                </li>
                            </ul>
                            <span class="c-footer-copyright">
                                <span class="c-footer-copyright__text">Version {this.props.version} © Volkswagen Financial Services <span>2023-2024</span></span>
                            </span>
                        </nav>
                    </div>
                </div>
            </footer>
        );
    }
} export default Footer

